import React from 'react'
import Link from 'gatsby-link'
import { graphql } from "gatsby"
import moment from 'moment'
import Layout from "../../components/layout"

export const query = graphql`
  query NewsArchiveQuery {
    site {
      siteMetadata {
        title
      }
    }
    news: allMarkdownRemark(filter: {frontmatter: {type: {eq: "news"}}}) {
      edges {
        node {
          id
          headings {
            value
            depth
          }
          fields {
            slug
            title
          }
          frontmatter {
            nid
            vid
            type
            date
            year
            cover_filepath
            image_filepath
            official
            status
            timestamp
            spotifylink
            ituneslink
            # youtubelink
          }
          html
          excerpt
        }
      }
    }
    releases: allMarkdownRemark(filter: {frontmatter: {type:{glob: "*release"}}}) {
      edges {
        node {
          id
          headings {
            value
            depth
          }
          fields {
            slug
            title
          }
          frontmatter {
            nid
            vid
            type
            news
            date
            year
            cover_filepath
            image_filepath
            official
            status
            timestamp
            spotifylink
            ituneslink
            # youtubelink
          }
          html
          excerpt
        }
      }
    }
  }
`


const NewsArchivePage = ({data}) => {
  var releasesInNews = data.releases.edges
    .filter(t => t.node && t.node.fields && t.node.fields.slug)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1 && t.node.frontmatter.news === 1)

  var sortednews = [].concat(data.news.edges, releasesInNews)
    .filter(t => t.node && t.node.fields && t.node.fields.slug)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1)
    .map(t => ({...t, timestamp: Date.parse(t.node.frontmatter.date)}))
    .sort((a, b) => b.timestamp - a.timestamp);

  return <Layout>
    <h1>News archive</h1>

    {
      sortednews.map(t => {
        return <span key={t.node.id}>
          {moment(t.node.frontmatter.date).format("YYYY-MM-DD")}
          {' '}
          <Link to={'/' + t.node.fields.slug}>
            {t.node.fields.title}
          </Link>
          <br />
        </span>;
      })
    }
    {/* <h4>debug:</h4>
    <pre>{JSON.stringify(data, null, 2)}</pre> */}
  </Layout>;
}

export default NewsArchivePage
